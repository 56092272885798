import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addItemToCart,
  changeActiveCart,
  clearCart,
  clearDiscountForItemsInCart,
  toggleCheckoutOrder,
  updateDiscountForItemsInCart,
  updateItemNoteInCart,
} from "redux/cart";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import IconButton from "components/IconButton/IconButton";
import PriceListItem from "./PriceListItem/PriceListItem";
// import { AuthContext } from "context/authContext";
import classes from "./Cart.module.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { toggleAddingService } from "redux/createOrder";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { OrderServices } from "services/OrderServices";
import { useMemo } from "react";
import CartHeader from "./CartHeader";
import SingleOrderType from "./SingleOrderType";
import OrderNotesModal from "./orderNotesModal/OrderNotesModal";
// import OrderDiscountModal from "./orderDiscountModal/OrderDiscountModal";
import {
  addEditingOrder,
  changeActiveService,
  initialUpdateDiscountType,
  saveOrderTaxes,
  toggleActiveCheckoutOrder,
  updateDiscountInPrimaryDrawerCart,
  // updateSelectedOrdersForDiscount,
  updateSelectedOrdersForOrderNote,
} from "redux/editingOrder";
import {
  ORDER_DISCOUNT_PERMISSION,
  ORDER_COMPLETE_PERMISSION,
  ORDER_UPDATE_PERMISSION,
} from "constants/constants";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import { useDefaultSetting } from "hooks/useDefaultSetting";
import OrderItemNoteModal from "./orderItemNoteModal/OrderItemNoteModal";
import OrderDiscountModalNew from "./orderDiscountNewModal/OrderDiscountModalNew";
import Icon from "components/Icon/Icon";

// import { setCheckoutChannel } from "redux/channel";
// import { setCheckoutTender } from "redux/tenders";

const MainCart = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { loggedInUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const cartState = useSelector((state) => state.cart);
  const createOrder = useSelector((state) => state.createOrder);
  const { selectedLocation, activeLocation } = useSelector(
    (state) => state.location
  );
  const { order } = useSelector((state) => state.editOrder);
  const [isLoading, setIsLoading] = useState(false);
  const { translations: servicesTranslations } = useSelector(
    (state) => state.service
  );
  const [modal, setModal] = useState({
    noteModal: false,
    discountModal: false,
  });
  const { selectedOrders } = useSelector((state) => state.editOrder);
  //   const { selectedRestaurant } = useSelector((state) => state.restaurant);
  const [discount, setDiscount] = useState({
    type: "percentage",
    value: "",
    order_note: "",
    activeItem: null,
  });
  const [itemNote, setItemNote] = useState({
    activeItem: null,
    showItemNoteModal: false,
    itemNote: "",
  });

  // To determine if the logged in user can update an order
  const canUpdateOrder = usePermissionHooks(ORDER_UPDATE_PERMISSION);
  const { canView: canAddOrderDiscount } = usePermissionHooks(ORDER_DISCOUNT_PERMISSION);
  const { paymentChannelDestination } = useDefaultSetting();
  const canCompleteOrder = usePermissionHooks(ORDER_COMPLETE_PERMISSION);

  // Using this flow since one discount works for all services in a order for now
  const activeOrder = selectedOrders.filter(
    (item) => item.type === cartState?.activeCart
  );
  //  Setting the active service on cart page load incase user does not click it
  useMemo(() => {
    if (createOrder?.isEditing) {
      dispatch(changeActiveService(cartState?.activeCart));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrder?.isEditing]);

  // const discount_value = Number(activeOrder[0]?.discount_value);
  // const discount_type =
  //   activeOrder[0]?.discount_type === "amount" || !activeOrder[0]?.discount_type
  //     ? "percentage"
  //     : activeOrder[0]?.discount_type;
  // const order_comment = activeOrder[0]?.comment || "";

  // Using this function to set the default discount type to percentage for the services since backends keeps returning amount which is // no a discount type
  const setCartDiscounts = () => {
    selectedOrders.forEach((el) => {
      if (Number(el?.discount_value) === 0) {
        dispatch(
          initialUpdateDiscountType({
            type: el?.type,
            discount_type: "percentage",
          })
        );
      }
    });
  };

  useEffect(() => {
    setCartDiscounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Setting the discount for the selected order if available
  // useEffect(() => {
  //   if (discount_value > 0) {
  //     setDiscount({
  //       ...discount,
  //       value: discount_value,
  //       type: discount_type,
  //       order_note: order_comment,
  //     });
  //   } else {
  //     setDiscount({
  //       ...discount,
  //       value: "",
  //       type: discount_type,
  //       order_note: order_comment,
  //     });
  //     dispatch(
  //       updateSelectedOrdersForDiscount({
  //         type: activeOrder[0]?.type,
  //         discount_type,
  //         discount_value,
  //       })
  //     );
  //   }

  // }, [discount_value, dispatch, activeOrder[0]?.type]);

  // useEffect(() => {
  //   if(discount?.activeItem){

  //   }
  // }, []);

  // Setting the order item note when an item is clicked
  useEffect(() => {
    if (itemNote && itemNote.activeItem) {
      setItemNote({ ...itemNote, itemNote: itemNote.activeItem?.notes ?? "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemNote.activeItem]);

  const radioChangeHandler = (newValue) => {
    dispatch(changeActiveCart(newValue));
  };

  /**
   * @function To get the active tab total price
   * @param It takes the active order tab
   */

  const getActiveTabTotalPrice = (val) => {
    let price = 0;
    const currentTab = Object.keys(cartState?.cart);
    currentTab.forEach((el) => {
      const currentOrderArray = cartState?.cart[val];
      // If the length of currentOrderArray is greater than 1
      if (currentOrderArray?.length >= 1) {
        if (val === el) {
          for (const cartItem of cartState?.cart[val] || []) {
            const item_price =
              +(cartItem?.price + cartItem?.vat) * cartItem?.quantity;

            // Calculate the discounted value for the current item
            const discountedValue =
              cartItem.discount_type === "percentage"
                ? item_price -
                (item_price * Number(cartItem.discount_value ?? 0)) / 100
                : item_price - Number(cartItem.discount_value ?? 0);
            // Add the discounted value to the total price
            price += discountedValue;
          }
        }
      } else {
        price = 0;
      }
    });

    const activeOrderDiscount = selectedOrders.find(
      (orderItem) => orderItem.type === val
    );
    const priceAfterDiscount =
      Number(activeOrderDiscount?.discount_value) > 0
        ? activeOrderDiscount?.discount_type === "percentage"
          ? price - (price * Number(activeOrderDiscount?.discount_value)) / 100
          : price - Number(activeOrderDiscount?.discount_value)
        : price;

    return priceAfterDiscount.toFixed(3);
  };

  // Determining the outlet table id to use depending on the action type (create or update)
  const dineInOrder = selectedOrders?.filter((el) => el.type === "dine in");
  const isDineInOrder = dineInOrder && dineInOrder?.length > 0;
  const outletTableId =
    isDineInOrder && createOrder?.table?.id !== -1
      ? createOrder?.table?.id
      : dineInOrder[0]?.outlet_table_id;

  // Line of thought is trying to update the selected orders too when user wants to edit an order

  /**
   * @function This function decides where the user is directed to depending on the services they have chossen after the order has been placed
   */

  const pageDestination = () => {
    if (
      createOrder.orderTypes.length === 3 &&
      createOrder.orderTypes.includes("dine in")
    ) {
      return {
        home: false,
        active: "delivery",
      };
    } else if (
      createOrder.orderTypes.length === 2 &&
      !createOrder.orderTypes.includes("dine in")
    ) {
      return {
        home: false,
        active: "delivery",
      };
    } else if (
      createOrder.orderTypes.length === 2 &&
      createOrder.orderTypes.includes("dine in") &&
      createOrder.orderTypes.includes("takeaway")
    ) {
      return {
        home: true,
        active: "dine in",
      };
    } else if (
      createOrder.orderTypes.length === 2 &&
      createOrder.orderTypes.includes("dine in") &&
      createOrder.orderTypes.includes("delivery")
    ) {
      return {
        home: false,
        active: "delivery",
      };
    } else if (
      createOrder.orderTypes.length === 1 &&
      createOrder.orderTypes.includes("dine in")
    ) {
      return {
        home: true,
        active: "dine in",
      };
    } else if (
      createOrder.orderTypes.length === 1 &&
      !createOrder.orderTypes.includes("dine in")
    ) {
      return {
        home: false,
        active: createOrder.orderTypes[0],
      };
    } else {
      return { home: true, active: "dine in" };
    }
  };

  /**
   * @function This functions determines where to go to based on if default channel or tender has been configured
   */
  // const paymentChannelDestination = () => {
  //   if (default_channel && default_tender) {
  //     dispatch(setCheckoutChannel({ checkoutChannel: { ...default_channel } }));
  //     dispatch(setCheckoutTender(default_tender));
  //     navigate("/orders/checkout/receipt");
  //   } else if (default_channel) {
  //     dispatch(setCheckoutChannel({ checkoutChannel: { ...default_channel } }));
  //     navigate("/orders/checkout/payment-channel");
  //   } else if (default_tender) {
  //     dispatch(setCheckoutTender(default_tender));
  //     navigate("/orders/checkout/payment-channel");
  //   } else {
  //     navigate("/orders/checkout/payment-channel");
  //   }
  // };

  // console.log(loggedInUser?.name, "Restaurant");

  // For the update single order flow
  // const updateOrder = cartState?.cart;

  const placeOrder = (type) => {
    //  outlet_table_id: createOrder?.isEditing
    //    ? order.outlet_table_id
    //    : createOrder?.table?.id,
    setIsLoading(true);
    const orderData = {
      restaurant_id: activeLocation.restaurant_id,
      location_id: selectedLocation,
      outlet_table_id:
        createOrder?.isEditing && isDineInOrder
          ? outletTableId
          : createOrder?.table?.id,
      party_size: createOrder.partySize.value,
      customer_name: createOrder.partySize.customer_name,
      customer_phone: createOrder.partySize.customer_number,
      // uuid: selectedOrders,

      // uuid()
    };

    if (type === "create") {
      OrderServices.placeOrders(
        { orderData, orders: cartState.cart, selectedOrders },
        (res) => {
          const { home, active } = pageDestination();
          if (home || !canCompleteOrder.canView) {
            toast.success("Order placed successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            // if (home || !canCompleteOrder.canView) {
            //   toast.success("Order placed successfully!", {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            //   });
            // if (!canCompleteOrder.canView) {
            //   toast.error(
            //     "You do not have permission to proceed to checkout!",
            //     {
            //       position: toast.POSITION.BOTTOM_RIGHT,
            //     }
            //   );
            // }
            navigate("/orders/all");
            setIsLoading(false);
          } else {
            // Data response
            const mainOrderOne = res?.filter(
              (item) => item?.data?.data?.type === active
            );
            const mainOrder = mainOrderOne[0]?.data?.data?.data;
            // This is here to make sure it doesn't break if user doesn't add dish to the order if it contains a delivery services which is not sent to the backend but breaks this flow since its supposes to run for delivery services
            if (!mainOrder || !canCompleteOrder.canView) {
              toast.success("Order placed successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              navigate("/orders/all");
              setIsLoading(false);
              return;
            }
            dispatch(clearCart());
            for (const arr of createOrder.orderTypes) {
              const orderItems = res?.filter(
                (item) => item?.data?.data?.type === arr
              );
              // Saving the taxes from backend to use in calculation
              dispatch(
                saveOrderTaxes({
                  service: arr,
                  value: orderItems[0]?.data?.data?.data?.vatvalue,
                })
              );
              for (const orderItem of orderItems[0]?.data?.data?.data
                ?.orderItems) {
                const item = {
                  category: { id: orderItem.category_id, name: "" },
                  order_item_id: orderItem.id ?? '',
                  id: orderItem.item.id,
                  name: orderItem.item.name,
                  price: Number(orderItem.item.price),
                  quantity: orderItem.quantity,
                  amount: orderItem.amount,
                  order_id: orderItem?.order_id,
                  // order_id: orderItems[0]?.data?.data?.data?.id,
                  uuid: orderItems[0]?.data?.data?.data.uuid,
                  sizes: orderItem?.item?.sizes,
                  translations: orderItem?.item?.translations,
                  vatvalue: orderItem?.item?.vat,
                  order_vat: orderItem?.vat,
                  total_discount: Number(orderItem?.total_discount),
                  discount_value: orderItem?.discount,
                  discount_type: orderItem?.discount_type,
                  notes: orderItem?.notes,
                };
                dispatch(addItemToCart({ ...item, cartType: arr }));
              }
            }

            setIsLoading(false);
            dispatch(addEditingOrder(mainOrder));
            dispatch(changeActiveService(active));
            // Setting the checkout order
            dispatch(
              toggleCheckoutOrder({
                type: active,
                orderItems: cartState?.cart ? cartState?.cart[active] : [],
                status: mainOrder?.status,
                order_no: mainOrder?.order_no,
                id: mainOrder?.id,
                outlet_table_id: mainOrder?.outlet_table_id,
                vatvalue: mainOrder?.vatvalue,
                order_price: mainOrder?.order_price,
                total: mainOrder?.total,
                discount_type: mainOrderOne[0]?.data?.data?.discount_type,
                discount_value: mainOrderOne[0]?.data?.data?.discount_value,
                outletTable: mainOrder?.outletTable,
                client_name: mainOrder?.client_name,
                created_at: mainOrder?.created_at,
                total_discount: Number(mainOrder.total_discount),
              })
            );
            dispatch(
              toggleActiveCheckoutOrder({
                type: active,
                orderItems: cartState?.cart ? cartState?.cart[active] : [],
                status: mainOrder?.status,
                order_no: mainOrder?.order_no,
                id: mainOrder?.id,
                outlet_table_id: mainOrder?.outlet_table_id,
                vatvalue: mainOrder?.vatvalue,
                order_price: mainOrder?.order_price,
                total: mainOrder?.total,
                discount_type: mainOrderOne[0]?.data?.data?.discount_type,
                discount_value: mainOrderOne[0]?.data?.data?.discount_value,
                outletTable: mainOrder?.outletTable,
                client_name: mainOrder?.client_name,
                created_at: mainOrder?.created_at,
                total_discount: Number(mainOrder.total_discount),
              })
            );
            setTimeout(() => {
              paymentChannelDestination(mainOrder?.id);
              // navigate("/orders/checkout/payment-channel");
            }, 1000);
            dispatch(changeActiveCart(active));
            // dispatch(changeActiveCart(cartState?.checkoutOrder?.type));
          }
          // dispatch(fetchTables());
          // dispatch(fetchZones());
          // console.log(res, "Res");
          // props.onClear();
        },
        (err) => {
          if (err && err?.response?.data?.message) {
            Object.entries(err?.response?.data?.message).forEach(([key, messages]) => {
              messages.forEach((msg) => {
                toast.error(msg, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              });
          });
          } else if (
            err?.response?.data?.message &&
            Array.isArray(Object.values(err.response.data.message))
          ) {
            const errors = Object.values(err.response.data.message);
            toast.error(errors[0][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            // for (const key in err.response.data.message) {
            //   toast.error(err.response.data.message[key][0], {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            //   });
            // }
          } else {
            toast.error("Error occurred when creating your order", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          // else if (err && err.response?.data) {
          //   toast.error("Error occurred while creating your order", {
          //     position: toast.POSITION.BOTTOM_RIGHT,
          //   });
          // }
          setIsLoading(false);
        }
      );
    } else {
      OrderServices.updateOrders(
        {
          orderData,
          orders: cartState.cart,
          selectedOrders,
        },
        (res) => {
          setIsLoading(false);
          props.onClear();
          toast.success("Order Updated successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          navigate("/orders/all");
        },
        (err) => {
          setIsLoading(false);
          if (
            err?.response?.data?.message &&
            !Array.isArray(err?.response?.data?.message)
          ) {
            if (err?.response?.data?.message?.outlet_table_id) {
              toast.error(err.response.data.message?.outlet_table_id[0], {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else if (err?.response?.data?.message["orders.0.item_id"]) {
              for (const key of Object.keys(err.response.data.message)) {
                toast.error(err.response.data.message[key][0], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
            } else {
              toast.error(err?.response?.data?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else if (
            err?.response?.message &&
            Array.isArray(err?.response?.message)
          ) {
            const errors = Object.values(err?.response?.message);
            toast.error(errors[0][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (
            err?.response?.data?.message &&
            Array.isArray(err?.response?.data?.message)
          ) {
            const errors = Object.values(err?.response?.data?.message);
            toast.error(errors[0][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error("Error occurred when updating your order", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setIsLoading(false);
        }
      );
    }
  };

  const addServices = () => {
    dispatch(toggleAddingService(true));
    navigate("/orders/create/order-type");
  };

  //   const styleClasses = [classes.Cart, props.customClasses].join(" ");
  // const cartTypeData =
  //   createOrder.isEditing &&
  //   selectedOrders.length > createOrder.orderTypes.length > true &&
  //   false;


  useMemo(() => {
    if (order && order.id && cartState) {
      let orderId = "";
      if (
        typeof cartState?.cart["dine in"] === "object" &&
        cartState?.cart["dine in"]?.length > 0 &&
        orderId.length === 0
      ) {
        orderId = cartState?.cart["dine in"][0]?.order_id;
      }
      if (
        typeof cartState?.cart["takeaway"] === "object" &&
        cartState?.cart["takeaway"]?.length > 0 &&
        orderId?.length === 0
      ) {
        orderId = cartState?.cart["takeaway"][0]?.order_id;
      }
      if (
        typeof cartState?.cart["delivery"] === "object" &&
        cartState?.cart["delivery"]?.length > 0 &&
        orderId?.length === 0
      ) {
        orderId = cartState?.cart["delivery"][0]?.order_id;
      }
      // if (orderId === order?.id) {
      //   setOrderNumber("#" + order.order_no);
      // }

      setDiscount({ ...discount, order_note: selectedOrders[0]?.comment || "" })

    }
  }, [cartState, order]);

  const isDiscountApplied = () => {
    const activeOrderDiscounts = selectedOrders.find(
      (item) => item.type === cartState.activeCart
    );
    const activeDiscountValue = activeOrderDiscounts?.discount_value ?? 0;

    if (Number(activeDiscountValue) > 0) {
      return true;
    }

    const activeCartItems = cartState.cart[cartState.activeCart] ?? [];
    return activeCartItems.some(
      (item) => Number(item?.discount_value ?? 0) > 0
    );
  };

  return (
    <div className={`h-full ${classes.MainCart}`}>
      <LoadingScreen show={isLoading} />
      {/* Header */}
      <div className="w-full row-span-1 row-start-1 overflow-y-auto pb-5">
        <CartHeader
          addServices={addServices}
          activeOrder={cartState?.activeCart}
        />
        {/* TABS */}
        {Object.keys(cartState.cart).length > 0 && (
          <Fragment>
            {/* Order Types */}
            <div className="w-full grid grid-cols-3 gap-5 pt-2">
              {selectedOrders?.map((type) => {
                return (
                  <SingleOrderType
                    key={type?.type}
                    textLg1={type.type}
                    value={type.type}
                    textLg2={servicesTranslations?.ar[type.type] || ""}
                    isChecked={cartState?.activeCart === type?.type}
                    onChanged={(val) => {
                      radioChangeHandler(val);
                    }}
                    totalCartPrice={getActiveTabTotalPrice(type?.type)}
                    active={cartState?.activeCart}
                  />
                );
              })}
            </div>
            <div className={classes.PriceList}>
              {cartState?.cart[cartState?.activeCart]?.map((item, index) => {
                return (
                  <PriceListItem
                    key={index}
                    item={item}
                    id={item.item_id}
                    catId={item.cat_id}
                    index={index + 1}
                    name={item.item_name}
                    quantity={item.quantity}
                    price={item.price + item.vat}
                    sizes={item?.sizes}
                    translations={item?.translations}
                    onRemove={props.removeDishFromCart}
                    onReduceItemQuantity={props.onReduceItemQuantity}
                    onIncreaseItemQuantity={props.onIncreaseItemQuantity}
                    noteClicked={() => {
                      setItemNote({
                        ...itemNote,
                        activeItem: item,
                        showItemNoteModal: true,
                      });
                    }}
                    discountClicked={() => {
                      setModal({
                        ...modal,
                        discountModal: !modal.discountModal,
                      });
                      setDiscount({
                        ...discount,
                        activeItem: item,
                        value: item?.discount_value,
                        type: item?.discount_type,
                      });
                    }}
                  />
                );
              })}
            </div>
          </Fragment>
        )}
      </div>

      {/* Discount and button control */}
      <div className="w-full pb-10 ">
        {/* A minor fix to the update issue  */}
        {/* Discount and notes  */}
        <div className="w-full mb-10 bg-[#FFF3EB] rounded py-5 flex flex-row items-center justify-between px-8 ">
          <span className="font-Inter text-xl text-textColor">Add</span>
          <div className="flex flex-row items-center justify-end w-[50%] space-x-3">
            {isDiscountApplied() ? (
              <span className="flex items-center space-x-2 font-Inter text-xl min-w-fit font-medium cursor-pointer bg-[#D97706] text-white py-2 px-4 rounded-full">
                <Icon
                  name="check"
                  height={1.6}
                  width={1.6}
                  className="fill-primary text-primary"
                />
                <span className="mr-1 -mt-0.5">Discount Added</span>
              </span>
            ) : null}
            {
              canAddOrderDiscount && (
                <span
                  onClick={
                    cartState?.cart[cartState?.activeCart]?.length > 0
                      ? () => setModal({ ...modal, discountModal: true })
                      : () =>
                        toast.warning("Please add a dish", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        })
                  }
                  className="font-Inter text-xl text-primary font-medium cursor-pointer"
                >
                  {isDiscountApplied() ? "Change" : "Discount"}
                </span>
              )
            }
            <span
              onClick={
                cartState?.cart[cartState?.activeCart]?.length > 0
                  ? () => setModal({ ...modal, noteModal: true })
                  : () =>
                    toast.warning("Please add a dish", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    })
              }
              className="font-Inter text-xl text-primary font-medium cursor-pointer ml-2"
            >
              Notes
            </span>
          </div>
        </div>

        <div className={classes.CheckoutButtons}>
          {!createOrder.isEditing && (
            <IconButton
              customClasses={classes.CancelBtn}
              icon="trash"
              iconPosition="left"
              text={`${t("Cancel Order")} الغاء الطلب`}
              type="secondary"
              onClicked={() => navigate("/orders/all")}
            // disabled={cartState.cart[cartState.activeCart].length === 0}
            />
          )}

          {!createOrder.isEditing && (
            <IconButton
              customClasses={classes.ProceedBtn}
              icon="arrow-right"
              iconPosition="right"
              text={`${t("Confirm")} يتأكد`}
              onClicked={() => placeOrder("create")}
              disabled={cartState?.cart[cartState?.activeCart]?.length === 0}
            />
          )}
          {createOrder.isEditing && (
            <PrimaryButton
              customClasses={classes.AcceptBtn}
              text={t("ACCEPT")}
              onClicked={
                !canUpdateOrder.canView
                  ? () => {
                    toast.warning(
                      "You don not have permission to perform this action",
                      {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      }
                    );
                  }
                  : () => placeOrder("update")
              }
              isDisabled={
                cartState?.cart[cartState?.activeCart]?.length === 0 ||
                activeOrder[0]?.status === "complete"
              }
            />
          )}
        </div>
      </div>

      <OrderNotesModal
        showModal={modal.noteModal}
        onProceed={() => {
          dispatch(
            updateSelectedOrdersForOrderNote({
              type: cartState?.activeCart,
              order_note: discount?.order_note,
            })
          );
          // setDiscount({ ...discount, order_note: "" });
          setModal({ ...modal, noteModal: !modal.noteModal });
        }}
        toggleModal={() => setModal({ ...modal, noteModal: !modal.noteModal })}
        value={discount.order_note}
        onChange={(val) => setDiscount({ ...discount, order_note: val })}
        placeholder="Write your note for the order"
        disabled={!discount.order_note}
      />
      <OrderItemNoteModal
        showModal={itemNote.showItemNoteModal}
        onProceed={() => {
          dispatch(
            updateItemNoteInCart({
              type: cartState?.activeCart,
              note: itemNote?.itemNote,
              name: itemNote?.activeItem?.item_name,
            })
          );
          setItemNote({
            ...itemNote,
            activeItem: "",
          });
          setItemNote({
            ...itemNote,
            showItemNoteModal: !itemNote.showItemNoteModal,
          });
        }}
        toggleModal={() =>
          setItemNote({
            ...itemNote,
            showItemNoteModal: !itemNote.showItemNoteModal,
          })
        }
        value={itemNote.itemNote}
        onChange={(val) => setItemNote({ ...itemNote, itemNote: val })}
        placeholder="Write your note for the item"
        disabled={!itemNote.itemNote}
      />
      <OrderDiscountModalNew
        showModal={modal.discountModal}
        toggleModal={() =>
          setModal({ ...modal, discountModal: !modal.discountModal })
        }
        disabled={!discount.value}
        value={discount.value}
        onChange={(val) => setDiscount({ ...discount, value: val })}
        discount_type={discount.type}
        changeDiscountType={(val) => setDiscount({ ...discount, type: val })}
        activeItem={discount?.activeItem}
        onProceed={(OrderRes, discount) => {
          setModal({ ...modal, discountModal: !modal.discountModal });
          if (discount.type === "item") {
            dispatch(
              updateDiscountInPrimaryDrawerCart({
                discount: 0,
                discount_type: "percentage",
                type: discount.cartType,
              })
            );
            dispatch(updateDiscountForItemsInCart(OrderRes));
          } else if (discount.type === "order") {
            dispatch(clearDiscountForItemsInCart(OrderRes));
            dispatch(
              updateDiscountInPrimaryDrawerCart({
                discount: discount?.discountInfo?.discount_value,
                discount_type: discount?.discountInfo?.discount_type,
                type: discount.cartType,
              })
            );
          }
          setDiscount({ ...discount, type: "", value: "", activeItem: null });
        }}
      />
      {/* <OrderDiscountModal
        showModal={modal.discountModal}
        toggleModal={() =>
          setModal({ ...modal, discountModal: !modal.discountModal })
        }
        disabled={!discount.value}
        value={discount.value}
        onChange={(val) => setDiscount({ ...discount, value: val })}
        discount_type={discount.type}
        changeDiscountType={(val) => setDiscount({ ...discount, type: val })}
        activeItem={discount?.activeItem}
        onProceed={() => {
          setModal({ ...modal, discountModal: !modal.discountModal });
          dispatch(
            updateItemDiscountInCart({
              type: cartState?.activeCart,
              name: discount?.activeItem?.item_name,
              discount_value: discount?.value,
              discount_type: discount?.type,
            })
          );
          setDiscount({ ...discount, type: "", value: "", activeItem: null });
        }}
      /> */}
    </div>
  );
};

export default MainCart;
