import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  // useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchCategories, setSelectedCategory } from "redux/category";
import {
  fetchCategoryDishes,
  clearDishes,
  updateDishStock,
  incrementDishUsedStock,
  decrementDishUsedStock,
  updateDishUsedStock,
} from "redux/dish";
import {
  addItemToCart,
  clearCart,
  increaseItemInCart,
  reduceItemInCart,
  removeItemFromCart,
} from "redux/cart";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

import FilterCategoryTab from "components/FilterCategoryTab/FilterCategoryTab";
// import Cart from "components/Cart/Cart";
import Dish from "components/Dish/Dish";
import Loader from "components/Loader/Loader";
import classes from "./DishSelection.module.scss";
import { useTranslation } from "react-i18next";
import { clearOrder } from "redux/createOrder";
import { AuthContext } from "context/authContext";
import FilterCategoriesDropdown from "components/FilterCategoriesDropdown/FilterCategoriesDropdown";
import { DishServices } from "services/DishServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
// import IMG from "../../../../assets/img/emptyMenu.webp";
import { useWindowSize } from "hooks/useWindowSize";
// import useEventListener from "hooks/useEventListener";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";
import MainCart from "components/Cart/MainCart";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
// import { updateDiscountForCreateOrder } from "redux/editingOrder";

// const LIMIT = 15;
const DishSelection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    selectedCategory,
    categories,
    isLoading: categoriesLoading,
  } = useSelector((state) => state.category);
  const { width } = useWindowSize();
  const {
    dishes,
    isLoading: dishLoading,
    error,
    extra,
  } = useSelector((state) => state.dish);
  const { isEditing } = useSelector((state) => state.createOrder);
  const { cart, activeCart } = useSelector((state) => state.cart);
  const [filteredCategory, setFilteredCategory] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const { token } = useContext(AuthContext);
  const [isCartOpen, setIsCartOpen] = useState(true);
  // For pagination
  const [page, setPage] = useState(1);
  // const offset = (page - 1) * LIMIT;
  const total_pages = (extra && extra?.total_pages) || 1;

  const onChangeFilter = (newValue) => {
    const foundCategory = categories.find((category) => {
      return category.name === newValue;
    });
    dispatch(setSelectedCategory(foundCategory?.id));
    setFilteredCategory(foundCategory?.name);
    setPage(1);
  };

  // const getStock = (id, catId) => {
  //   const dishIndex = dishes?.findIndex((dishItem) => dishItem?.id === id);

  //   const usedStock = dishes[catId][dishIndex]?.usedStock;
  //   const availableStock = dishes[catId][dishIndex]?.availableStock;

  //   return { usedStock, availableStock };
  // };

  const addDishtoCartHandler = async (dish, catId, cartItemIndex) => {
    // const { usedStock, availableStock } = getStock(dish.id, catId);

    // if (usedStock !== undefined && usedStock === availableStock) {
    //   return;
    // }
    // if (!availableStock) {
    //   console.log(dish, "Dish to be added");
    //   toast.error("Item ingredient stock quantity not enough to update order", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //   });
    //   return;
    // }

    // if (availableStock !== undefined) {
    //   dispatch(incrementDishUsedStock({ ...dish, catId: selectedCategory }));
    //   return dispatch(addItemToCart(dish));
    // }

    const selectedDish =
      cart && cart[activeCart]?.filter((item) => item?.item_id === dish?.id);
    const selectedDishIndexInCart =
      cart && cart[activeCart]?.findIndex((el) => el?.item_id === dish?.id);

    setisLoading(true);
    DishServices.getSingleItemStock(
      dish.id,
      selectedDish[0]?.order_item_id ?? "",
      selectedDishIndexInCart < 0 ? 1 : selectedDish[0].quantity + 1,
      (res) => {
        const dishData = res?.data?.data;

        if (res && res?.data?.data?.data?.available_stock >= 1) {
          setisLoading(false);
          // Checking if the dish is already present in cart increase its quantity  else just add to cart
          if (selectedDish[0]) {
            onIncreaseItemQuantity(
              selectedDishIndexInCart + 1,
              dish?.id,
              dish?.category_id,
              false
            );
          } else {
            dispatch(
              updateDishStock({ ...dishData?.data, cat_id: selectedCategory })
            );
            dispatch(addItemToCart(dish));
          }
        } else {
          setisLoading(false);
          toast.error(
            res.data?.data?.data?.message ||
              "Unable to get the stock of selected item.",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      },
      (err) => {
        setisLoading(false);
        toast.error("Unable to get the stock of selected item.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    );
  };

  /* MOVE THIS BACK TO CART COMPONENT WHEN PUTTING REAL API */
  /* Selected category will be repalced by the category objec that is inside the item */

  const onReduceItemQuantity = (cartItemIndex, itemId, catId) => {
    // const { usedStock } = getStock(itemId, catId);

    // if (usedStock === 0) {
    //   return;
    // }

    dispatch(reduceItemInCart(cartItemIndex));
    dispatch(decrementDishUsedStock({ id: itemId, catId: selectedCategory }));
  };

  /* MOVE THIS BACK TO CART COMPONENT WHEN PUTTING REAL API */
  /* Selected category will be repalced by the category objec that is inside the item */
  const onIncreaseItemQuantity = (
    cartItemIndex,
    itemId,
    catId,
    IconClick = true
  ) => {
    /*** Note */
    // const { usedStock, availableStock } = getStock(itemId, catId);
    // My own saved code
    // if (availableStock) {
    //   if (usedStock === availableStock) {
    //     return;
    //   }
    //   dispatch(increaseItemInCart(cartItemIndex));
    //   dispatch(incrementDishUsedStock({ id: itemId, catId: selectedCategory }));
    // } else {
    //   toast.error("Item ingredient stock quantity not enough to update order", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //   });
    // }
    /**
     * Sajid Khan initial code
     */
    // if (usedStock === availableStock) {
    //   return;
    // }

    if (IconClick) {
      const selectedDish =
        cart && cart[activeCart]?.filter((item) => item?.item_id === itemId);

      DishServices.getSingleItemStock(
        itemId,
        selectedDish[0]?.order_item_id ?? "",
        cartItemIndex < 0 ? 1 : selectedDish[0].quantity + 1,
        (res) => {
          const dishData = res?.data?.data;

          if (res && dishData?.data?.available_stock >= 1) {
            dispatch(increaseItemInCart(cartItemIndex));
            dispatch(
              incrementDishUsedStock({ id: itemId, catId: selectedCategory })
            );
          } else {
            toast.error(
              res.data?.data?.data?.message ||
                "No more stock available for this item.",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          }
        },
        (err) => {
          toast.error("Unable to get the stock of selected item.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      );
    } else {
      dispatch(increaseItemInCart(cartItemIndex));
      dispatch(incrementDishUsedStock({ id: itemId, catId: selectedCategory }));
    }
  };

  /* MOVE THIS BACK TO CART COMPONENT WHEN PUTTING REAL API */
  /* Selected category will be repalced by the category objec that is inside the item */
  const removeDishFromCart = (cartItemIndex, itemId, catId) => {
    const itemIndex = cart[activeCart].findIndex(
      (obj) => obj.item_id === itemId
    );
    dispatch(
      updateDishUsedStock({
        catId,
        id: itemId,
        usedStock: cart[activeCart][itemIndex]?.quantity,
      })
    );
    dispatch(removeItemFromCart(cartItemIndex));
  };

  /* Fetch dish for the selected category if not already */
  const fetchDishes = useCallback(() => {
    if (selectedCategory) {
      dispatch(
        fetchCategoryDishes({
          categoryId: selectedCategory,
          page: page ?? 1,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCategory, page]);

  const clearCartHandler = useCallback(() => {
    dispatch(clearDishes());
    dispatch(clearCart());
    dispatch(
      fetchCategoryDishes({
        categoryId: selectedCategory,
        page,
      })
    );
    dispatch(clearOrder());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCategory]);

  useEffect(() => {
    fetchDishes();
  }, [fetchDishes]);

  useEffect(() => {
    if (!categories && !isEditing) {
      return navigate("/orders");
    }

    if (isEditing && !categories) {
      return dispatch(fetchCategories(token));
    }

    if (!selectedCategory) {
      return dispatch(setSelectedCategory(undefined));
    }

    if (filteredCategory) return;

    const foundCategory = categories?.find((category) => {
      return category.id === selectedCategory;
    });
    setFilteredCategory(foundCategory?.name);
  }, [
    categories,
    selectedCategory,
    navigate,
    dispatch,
    isEditing,
    token,
    filteredCategory,
  ]);

  let displayedDishes = null;
  let noDishes = null;
  let errorMessage = null;

  const catDishes = dishes || [];
  // const catDishes =
  //   (dishes && dishes[selectedCategory] && dishes[selectedCategory]) || [];
  if (!dishLoading && !error && dishes && dishes !== undefined) {
    if (dishes?.length > 0) {
      displayedDishes = (
        <DisplayedDish
          // className={classes.AllDishes}
          isCartOpen={isCartOpen}
          className={`w-full grid md:grid-auto-fit-sm lg:grid-auto-fit-lg xl:grid-auto-fit-xl
           gap-5 `}
        >
          {catDishes
            // ?.slice(offset, offset + LIMIT)
            .map((dish, index) => {
              return (
                <Dish
                  onClicked={() =>
                    addDishtoCartHandler(dish, dish.category.id, index)
                  }
                  key={dish.name}
                  nameLg1={dish.name}
                  nameLg2={dish?.translations?.ar?.name}
                  img={`${process.env.REACT_APP_ASSETS_URL}/storage/${dish.image}`}
                  hasImage={dish.image !== "null"}
                  price={dish.price + dish.vat}
                  stock={dish.amount}
                  dish={dish}
                />
              );
            })}
        </DisplayedDish>
      );
    } else {
      noDishes = (
        <>
          <EmptyContainer className="w-full mt-4 flex flex-col items-center ">
            {/* <img src={IMG} /> */}
            <h3>
              {t("NOTF_NO_DISH")} "{filteredCategory}"
            </h3>
          </EmptyContainer>
        </>
      );
    }
  }

  // if (
  //   !dishLoading &&
  //   !error &&
  //   dishes &&
  //   dishes[selectedCategory] === undefined
  // ) {
  //   errorMessage = <p className={classes.Error}>{t("NOTF_SOMETHING_WRONG")}</p>;
  // }
  if (error && !dishLoading) {
    errorMessage = <p className={classes.Error}>{error}</p>;
  }

  return (
    <Fragment>
      <LoadingScreen show={isLoading} />
      <GlobalPadding hideRightPadding hideTopPadding>
        <div className="w-full h-full flex flex-row items-center  pr-[0px] relative overflow-y-auto">
          <div
            className={`${
              isCartOpen ? "xl:w-[60%] lg:w-[50%] w-[40%]" : "w-[95%]"
            } h-full transition-all duration-500 ease-in-out `}
          >
            {categories && (
              <div className={`w-[90%]`}>
                {width && width >= 1024 && (
                  <FilterCategoryTab
                    categories={categories}
                    selectedCategory={filteredCategory}
                    onChangeFilter={onChangeFilter}
                    customClasses={classes.FilterTab}
                  />
                )}

                <FilterCategoriesDropdown
                  categories={categories}
                  selectedCategory={filteredCategory}
                  onChangeFilter={onChangeFilter}
                />
              </div>
            )}
            {/* MainContent */}
            <div className={`${isCartOpen ? "w-[88%]" : "w-full"} `}>
              <div className="w-full flex flex-row items-center justify-between mt-14 mb-10">
                <h2 className="text-xl sm:text-2xl lg:text-3xl font-Inter font-semibold text-[#1F2937]">
                  {t("CHOOSE_DISH")}
                </h2>
                <p className="text-xl sm:text-2xl lg:text-3xl font-Inter font-semibold text-[#1F2937]">
                  اختر الطبق
                </p>
              </div>
              {/* Dishes */}
              {(dishLoading || categoriesLoading) && (
                <RemoveScroll>
                  <div className="h-screen">
                    <Loader />
                  </div>
                </RemoveScroll>
              )}
              {displayedDishes}
              {/* Pagination */}
              <div className="w-full">
                {!dishLoading && dishes && dishes?.length >= 1 && (
                  <div className="flex mt-10 pb-6">
                    <div className="mx-auto">
                      {total_pages && total_pages > 1 && (
                        <p className="text-sm text-gray-400 font-semibold font-Inter text-center  my-3">
                          Page {page} of&nbsp;
                          {total_pages}
                        </p>
                      )}
                      <Pagination
                        count={total_pages}
                        page={page}
                        onChange={(event, val) => {
                          setPage(val);
                        }}
                        renderItem={(item) => (
                          <PaginationItem
                            {...item}
                            shape="rounded"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="h-full">
                {catDishes && catDishes?.length > 3 ? (
                  <> {noDishes}</>
                ) : (
                  // <RemoveScroll>{noDishes}</RemoveScroll>
                  <>{noDishes}</>
                )}
              </div>
              {errorMessage ? (
                <div className="w-full h-[70vh]">{errorMessage}</div>
              ) : null}
            </div>
          </div>

          {/* Right Pane */}
          <div
            className={`${
              isCartOpen ? "xl:w-[40%] lg:w-[50%] w-[60%]" : "w-[3%]"
            } bg-white  flex flex-row items-center justify-between transition-all duration-500 ease-in-out fixed  top-0 bottom-0 right-0  overflow-y-hidden`}
          >
            <div
              role="button"
              onClick={() => setIsCartOpen(!isCartOpen)}
              className={`${
                isCartOpen ? "w-[5%]" : "w-full"
              } bg-gray-200 h-full flex flex-col items-center justify-center pt-[50px] cursor-pointer`}
            >
              {isCartOpen ? (
                <FaChevronRight className="text-2xl text-gray-400" />
              ) : (
                <FaChevronLeft className="text-2xl text-gray-400" />
              )}
            </div>
            {isCartOpen && (
              <div className="w-[95%]  h-full  pt-[150px] px-10">
                <MainCart
                  onReduceItemQuantity={onReduceItemQuantity}
                  onIncreaseItemQuantity={onIncreaseItemQuantity}
                  removeDishFromCart={removeDishFromCart}
                  customClasses={classes.CartBox}
                  onClear={clearCartHandler}
                />
              </div>
            )}
          </div>
        </div>
      </GlobalPadding>
    </Fragment>
  );
};

export default DishSelection;

export const EmptyContainer = styled.div`
  /* @media (min-width: 2000px) {
    height: 50vh;
  } */
`;

export const DisplayedDish = styled.div`
  /* @media (min-width: 2500px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media (max-width: 768px) {
    grid-template-columns: ${(props) =>
    props.isCartOpen
      ? "repeat(1, minmax(0, 1fr))"
      : "repeat(2, minmax(0, 1fr))"};
  } */
`;
