import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  cart: {},
  activeCart: null,
  checkoutOrder: {},
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart(state, action) {
      const cartType = action.payload.cartType || state.activeCart;
      if (state.cart[cartType] === undefined) state.cart[cartType] = [];

      const newItemId = action.payload.id;
      const itemIndex = current(state)?.cart[cartType]?.findIndex(
        (item) => newItemId === item.item_id
      );
  
      if (itemIndex === -1) {
        const item = {
          cat_id: action.payload.category.id,
          order_item_id: action.payload.order_item_id,
          cat_name: action.payload.category.name,
          item_id: action.payload.id,
          item_name: action.payload.name,
          price: action.payload.price,
          quantity: action.payload.quantity || 1,
          amount: action.payload.amount || 0,
          uuid: action.payload.uuid || undefined,
          sizes: action.payload.sizes || [],
          translations: action.payload.translations || {},
          vatvalue: Number(action?.payload?.vatvalue) || 0,
          order_vat: action?.payload?.order_vat,
          vat: action?.payload?.vat ? Number(action?.payload?.vat) : Number(action?.payload?.vatvalue) || 0.0,
          total_discount: action.payload?.total_discount ?? 0,
          notes: action?.payload?.notes ?? "",
          discount_value: action?.payload?.discount_value ?? 0,
          discount_type: action?.payload?.discount_type ?? "percentage",
          type:
            action.payload.cartType ||
            current(state)
              .activeCart.replace(/[A-Z]/g, " $&")
              .toLocaleLowerCase(),
        };

        // if(!action?.payload?.discount_value){
        //   delete item.discount_value
        //   delete item.discount_type
        // }

        if (action.payload.order_id) item.order_id = action.payload.order_id;
        state.cart[cartType].push(item);
      }
      // else {
      //   state.cart[cartType][itemIndex].quantity++;
      // }
    },
    updateItemNoteInCart(state, action) {
      const { type, note, name } = action.payload;
      state.cart[type].forEach((el) => {
        if (el?.item_name === name) {
          el.notes = note;
        }
      });
    },
    updateItemDiscountInCart(state, action) {
      const { type, name, discount_value, discount_type } = action.payload;
      state.cart[type].forEach((el) => {
        if (el?.item_name === name) {
          el.discount_value = Number(discount_value);
          el.discount_type = discount_type;
        }
      });
    },

    updateDiscountForItemsInCart(state, action) {
      const { payload, type } = action.payload;  
      // Update discount_type and discount_value for matched items
      state.cart[type].forEach(item => {
        const matchingPayloadItem = payload.items.find(payloadItem => payloadItem.id === item.item_id);
  
        if (matchingPayloadItem) {
            item.discount_type= matchingPayloadItem.discount_type;
            item.discount_value= matchingPayloadItem.discount_value;
            item.total_discount = matchingPayloadItem.saved;
        }
      });
    },
    
    clearDiscountForItemsInCart(state, action) {
      const { payload, type } = action.payload;
    
      // Create a new array of items with cleared discount_type and discount_value
      const updatedItems = state.cart[type].map(({ discount_type, discount_value, ...rest }) => ({
        discount_type: "fixed",
        discount_value: 0,
        ...rest
      }));
      
      // Update the state with the new array of items
      state.cart[type] = updatedItems;
    },

    removeItemFromCart(state, action) {
      state.cart[state.activeCart].splice(action.payload, 1);
    },
    reduceItemInCart(state, action) {
      state.cart[state.activeCart][action.payload - 1].quantity--;
    },
    increaseItemInCart(state, action) {
      state.cart[state.activeCart][action.payload - 1].quantity++;
    },
    increaseItemInCartInput(state, action) {
      state.cart[state.activeCart][action.payload.itemIndex - 1].quantity =
        action.payload.value;
    },
    changeActiveCart(state, action) {
      state.activeCart = action.payload;
    },
    toggleOrderTypesInCart(state, action) {
      if (state.cart[action.payload]) delete state.cart[action.payload];
      else state.cart[action.payload] = [];
    },
    toggleCheckoutOrder(state, action) {
      state.checkoutOrder = action.payload;
    },
    clearCart(state) {
      state.cart = {};
      state.activeCart = null;
      state.checkoutOrder = {};
    },
  },
});

export const {
  addItemToCart,
  removeItemFromCart,
  reduceItemInCart,
  increaseItemInCart,
  increaseItemInCartInput,
  changeActiveCart,
  toggleOrderTypesInCart,
  clearCart,
  toggleCheckoutOrder,
  updateItemNoteInCart,
  updateItemDiscountInCart,
  updateDiscountForItemsInCart,
  clearDiscountForItemsInCart
} = cartSlice.actions;
export default cartSlice.reducer;
