import axiosWithLocation from "config/axiosWithLocationV2";

export const DishServices = {
  getSingleItemStock: function (dishId, order_item_id = '', qty = 0, success, fail) {
    axiosWithLocation
      .post("/items/available", { item: [dishId], order_item_id, qty})
      .then(success) 
      .catch(fail);
  },
  getMultipleItemsStock: function (dishes, success, fail) {
    return axiosWithLocation
      .post("/items/available", { item: dishes })
      .then(success)
      .catch(fail);
  },
};
